import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { Badge, Button, COLOR, Shield } from '@extend/zen'
import { bp } from '@customers-ui'
import type { ProductDetailsProp } from './contract-details-page'
import type { CategoryContract, ContractsSearchIndividual, ExtendedWarrantyContract } from '../../types/contract'
import { images } from '../../lib/assets'
import { logEvent } from '../../analytics'
import { useIntl } from 'react-intl'
import type { DisplayDetails } from '../../lib/helper-functions'
import { missingProfileFieldTooltip } from '../../lib/helper-functions'
import { useSelector } from 'react-redux'
import { getAccountInfo } from '../../lib/consumer-profile-utils'
import * as selectors from '../../reducers/selectors'

export interface ContractDetailsHeaderProps {
  contract: ContractsSearchIndividual
  hasFulfilledClaim?: boolean
  product: ProductDetailsProp
  planTermsPdfUrl?: string
  shouldDisplayFileAClaimButton?: boolean
  displayDetails: DisplayDetails
  isClaimInProgress?: boolean
}

const ContractDetailsHeader: FC<ContractDetailsHeaderProps> = ({
  contract,
  product,
  hasFulfilledClaim,
  displayDetails,
  planTermsPdfUrl,
  shouldDisplayFileAClaimButton,
  isClaimInProgress,
}) => {
  const { transactionId } = contract as ExtendedWarrantyContract | CategoryContract
  const { lineItemId } = product
  const theme = useTheme()
  const { isExtend } = theme.merchantConfiguration
  const { locale } = useIntl()
  const history = useHistory()

  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const { isEmailVerified, isPhoneNumberVerified } = getAccountInfo(consumerProfile)
  const isMissingRequiredProfileField = !isEmailVerified || !isPhoneNumberVerified

  const { isClaimFilingDisabled, fileClaimTooltip, subBadgeText, badgeDetails, badgeColor } = displayDetails

  const handleTransferContractClick = (): void => {
    logEvent('Plan Details - Header - Clicks', 'Transfer contract')
    history.push(`/my_plans/${contract.id}/transfer`)
  }

  const handleFileAClaimClick = (): void => {
    logEvent('Plan Details - Header - Clicks', 'File a Claim')
    history.push(!isExtend ? `/${locale}/my_claims/file-claim` : `/${locale}/kaley`, {
      contractId: contract.id,
      ...(lineItemId && { lineItemIds: [lineItemId] }),
    })
  }

  const handleViewClaimInProgressClick = (): void => {
    logEvent('Plan Detials - Header - Clicks', 'View Claim In Progress')
    history.push(`/${locale}/my_claims`)
  }

  const contractIsCanceledOrRefunded = contract.status === 'cancelled' || contract.status === 'refund_paid'

  return (
    <HeaderContainer data-cy="contract-details-header">
      <HeaderTextContainer>
        <OrderNumber data-cy="header-order-number">Order # {transactionId}</OrderNumber>
        <ProductRow>
          <ProductName data-cy="header-product-name">{product.name ?? product.title}</ProductName>
          <Badge text="Product Protection" color="blue" data-cy="coverage-badge" icon={Shield} />
          <>
            {isClaimInProgress ? (
              <Badge
                emphasis="low"
                text={subBadgeText || 'Claim in Progress'}
                data-cy="in-progress-badge"
                color="green"
              />
            ) : (
              <Badge emphasis="low" text={badgeDetails} color={badgeColor} data-cy="status-badge" />
            )}
          </>
        </ProductRow>
        <ButtonContainer>
          {!contractIsCanceledOrRefunded && (
            <>
              {shouldDisplayFileAClaimButton ? (
                <Button
                  onClick={handleFileAClaimClick}
                  data-cy="header-file-a-claim"
                  text="File a Claim"
                  emphasis="medium"
                  color="neutral"
                  tooltip={isMissingRequiredProfileField ? missingProfileFieldTooltip : fileClaimTooltip}
                  isDisabled={!displayDetails || isClaimFilingDisabled || isMissingRequiredProfileField}
                />
              ) : (
                <Button
                  onClick={handleViewClaimInProgressClick}
                  text={`View Claim${hasFulfilledClaim ? '' : ' in Progress'}`}
                  data-cy="header-claim-in-progress"
                  emphasis="medium"
                  color="neutral"
                />
              )}
              {contract.status !== 'fulfilled' && (
                <Button
                  onClick={handleTransferContractClick}
                  data-cy="header-transfer-contract"
                  text="Transfer Contract"
                  emphasis="medium"
                  color="neutral"
                />
              )}
            </>
          )}
          <Button
            onClick={() => window.open(planTermsPdfUrl, '_blank')}
            text="View Terms and Conditions"
            data-cy="header-view-terms-conditions"
            emphasis="medium"
            color="neutral"
          />
        </ButtonContainer>
      </HeaderTextContainer>
      <ProductImage image={product.imageUrl} title={product.name ?? product.title} />
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div({
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'row',
  gap: 15,
  [bp.mobile]: {
    padding: '24px 0',
    flexDirection: 'column',
  },
  [bp.desktop]: {
    paddingTop: 60,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
})

const HeaderTextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
})

const OrderNumber = styled.div({
  fontSize: 14,
  fontWeight: 700,
  textTransform: 'uppercase',
  color: COLOR.NEUTRAL[600],
})

const ProductName = styled.div({
  fontSize: 24,
  fontWeight: 700,
})

const ButtonContainer = styled.div({
  display: 'flex',
  gap: 10,
  flexDirection: 'row',
  button: {
    width: 'auto',
  },
  [bp.mobile]: {
    button: {
      width: '100%',
    },
    flexDirection: 'column',
    gap: 15,
  },
  [bp.desktop]: {
    flexDirection: 'row',
    button: {
      width: 'auto',
    },
    gap: 10,
  },
})

const ProductRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
  marginBottom: 15,
})

const ProductImage = styled.div<{ image?: string }>(({ image }) => ({
  background: `no-repeat center center`,
  backgroundSize: 'cover',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 16,
  [bp.mobile]: {
    maxHeight: 72,
    maxWidth: 72,
    minHeight: 72,
    minWidth: 72,
    backgroundImage: `url(${image}), url(${images.defaultMobileProductImage})`,
  },
  [bp.desktop]: {
    maxHeight: 160,
    maxWidth: 160,
    minHeight: 160,
    minWidth: 160,
    backgroundImage: `url(${image}), url(${images.defaultProductImage})`,
  },
}))

export default ContractDetailsHeader
